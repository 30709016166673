<template>
  <NotFound v-if="notFound" type="tokenTag" :value="tokenTag" />
  <div v-else class="bg-scanMain pt-20 pb-12 lg:pt-28">
    <Container>
      <div class="flex items-center pl-4">
        <TokenLogo class="w-8 h-8 mr-1" :symbol="token ? token.symbol : ''" />
        <div class="flex flex-col">
          <span>{{ token ? token.symbol : '' }} {{ t('contained_everapy_txs') }}</span>
          <div class="text-12px text-everGray6 ">
            {{ t('source_chain') }}:{{ token ? formatChainTypeDisplay(token.chainType) : '' }}
          </div>
        </div>
      </div>
      <Tab :current-tx-options="currentTxOptions" @switch="switchTxOptions" />
      <Loading v-if="loading" />
      <TableCard
        v-else
        :current-tx-options="currentTxOptions"
        :tbody-list="txsList.txs" />
      <Pagination
        :loading="loading"
        :current-page="currentPage"
        :has-next-page="isNextPage"
        @jump="handlePageChange" />
    </Container>
  </div>
</template>

<script lang="ts">
import { ref, computed, defineComponent, Ref, onActivated, onDeactivated } from 'vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import { getTxsByTokenTag, getBundleTxsByToken } from '@/libs/api'
import Container from '@/components/Container.vue'
import TableCard from '@/components/TableCard.vue'
import Pagination from '@/components/common/Pagination.vue'
import Loading from '@/components/common/Loading.vue'
import NotFound from '@/components/common/NotFound.vue'
import { useI18n } from 'vue-i18n'
import TokenLogo from '@/components/TokenLogo.vue'
import Tab from '@/components/Tab.vue'
import { getTokenByTag } from '@/libs/everpay-js/utils/util'
import { handleErrorMsg, formatChainTypeDisplay } from '@/libs/utils'

export default defineComponent({
  components: {
    Container,
    TableCard,
    Pagination,
    Loading,
    NotFound,
    TokenLogo,
    Tab
  },
  setup () {
    const loading = ref(false)
    const notFound = ref(true)
    const store = useStore()
    const route = useRoute()
    const { t } = useI18n()
    const tokenList = computed(() => {
      return store.state.tokenList
    })
    const tokenTag = computed(() => route.params.tokenTag as string)
    const token = computed(() => {
      if (tokenTag.value) {
        return getTokenByTag(tokenTag.value, tokenList.value)
      }
      return undefined
    })
    const currentTxOptions = ref('Transaction')
    const switchTxOptions = (option: string) => {
      initConst()
      currentTxOptions.value = option
      getTagList()
    }
    // 初始化变量
    const initConst = () => {
      currentPage.value = 1
      pageCachelist.value = []
      isNextPage.value = false
      pageId.value = 0
      pageCacheIndex.value = 0
    }

    const currentPage = ref(1)
    const pageCacheIndex = ref(0)
    const pageCachelist = ref<number[]>([])
    const isNextPage = ref(false)
    const pageId = ref(0)
    const txsList: Ref<any> = ref({})
    const getTagList = async () => {
      if (token.value) {
        loading.value = true
        notFound.value = false
        try {
          if (pageCachelist.value[pageCacheIndex.value] !== undefined) {
            pageId.value = pageCachelist.value[pageCacheIndex.value]
          }
          if (currentTxOptions.value === 'Transaction') {
            txsList.value = await getTxsByTokenTag(token.value?.tag, pageId.value)
            isNextPage.value = txsList.value.hasNextPage
            if (pageCachelist.value[pageCacheIndex.value] === undefined) {
              pageCachelist.value.push(pageId.value)
            }
            if (txsList.value.hasNextPage) {
              pageId.value = txsList.value.txs[txsList.value.txs.length - 1].rawId
            }
          } else {
            txsList.value = await getBundleTxsByToken(token.value?.tag, pageId.value)
            isNextPage.value = txsList.value.hasNextPage
            if (pageCachelist.value[pageCacheIndex.value] === undefined) {
              pageCachelist.value.push(pageId.value)
            }
            if (txsList.value.hasNextPage) {
              pageId.value = txsList.value.txs[txsList.value.txs.length - 1].id
            }
          }
          notFound.value = false
        } catch (e:any) {
          notFound.value = true
          handleErrorMsg(e, t)
        }
        loading.value = false
      } else {
        notFound.value = true
      }
    }
    const handlePageChange = (page: number) => {
      currentPage.value = page
      pageCacheIndex.value = currentPage.value - 1
      getTagList()
    }
    onActivated(() => {
      initConst()
      getTagList()
    })
    onDeactivated(() => {
      txsList.value = {}
    })
    return {
      t,
      handlePageChange,
      txsList,
      token,
      loading,
      notFound,
      tokenTag,
      currentPage,
      currentTxOptions,
      switchTxOptions,
      formatChainTypeDisplay,
      isNextPage
    }
  }
})
</script>
